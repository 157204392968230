<template>
    <div class="">
        <!-- 专题征稿 -->
        <div class="center">
            <aside-part></aside-part>
            <div class="content">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>专题征稿</el-breadcrumb-item>
                </el-breadcrumb>
                <div class="title">
                    <div class="title_zh">专题征稿</div>
                    <div class="title_en">Special topic solicitation</div>
                </div>
                <!-- <div class="box">
                    <img src="@/assets/ing.png" alt="">
                    <p>内容更新中...</p>
                </div> -->
                <div class="specialSubject" v-for="(item, index) in list" :key="index">
                    <div class="subject">
                        {{ item.title }}
                    </div>
                    <div class="subjectList">
                        <div class="subjecContent">{{ item.content }}</div>
                        <div class="item" v-for="(item1, index1) in item.details" :key="index1">
                            <div class="itemTitle">{{ index1 + 1 + '、' + item1.institution }}</div>
                            <div class="itemContent">{{ item1.url }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import asidePart from '@/components/asidePart.vue';
export default {
    name: 'solicitContributions',
    components: {
        asidePart
    },
    data() {
        return {
            list: [
                {
                    title: '专题一：二氧化碳捕集利用和储存前沿进展',
                    content: '该专题旨在探究CCUS技术的规模化应用潜力及前沿技术进展。我们欢迎多种形式的投稿，从机理研究、到创新理念再到工业应用等，都是该专题的征稿对象。我们将邀请探讨CCUS技术各个方面的文章，特别强调原创研究、综述和观点文章。',
                    themeList: [
                        {
                            itemTitle: '二氧化碳捕获',
                            itemContent: '后燃烧捕获；前燃烧捕获；氧燃烧；化学吸收；膜分离；低温分离；固体吸附'
                        },
                        {
                            itemTitle: '二氧化碳利用',
                            itemContent: '微藻固碳技术；碳矿化；碳化学品；碳燃料；碳制品'
                        },
                        {
                            itemTitle: '二氧化碳储存',
                            itemContent: '地质储存；海洋储存'
                        }
                    ]
                },
                {
                    title: '专题二：太阳能/电解水析氢高效转换技术',
                    content: '以太阳能和电化学技术为驱动的析氢技术，是一条非常有发展前景的解决能源危机和实现碳中和的途径。太阳/电能产氢受到了研究人员的广泛关注并且取得了可喜的研究进展。本特刊征集以下主题，但不限于：',
                    themeList: [
                        {
                            itemTitle: '光催化析氢',
                            itemContent: '新型半导体光催化剂的设计与制备；光催化析氢催化机制探索；光催化析氢的工程示范。'
                        },
                        {
                            itemTitle: '光电化学析氢',
                            itemContent: '新型光阳极/光阴极的设计与制备；光电极的腐蚀研究；光电极的界面研究；光电极的表面态研究。'
                        },
                        {
                            itemTitle: '光伏-电催化析氢',
                            itemContent: '新型钙钛矿太阳能电池和电催化剂的设计与制备；光伏器件的集成和电催化剂的研究；光伏-电催化析氢的工程示范。'
                        },
                        {
                            itemTitle: '电催化析氢',
                            itemContent: '新型电极材料的设计与制备；电催化剂相变机理的研究；电催化剂析氢的工程示范。水分解过程的理论计算。'
                        },
                        {
                            itemTitle: '生物质转换耦合析氢',
                            itemContent: '新型生物质耦合制氢；生物材料转换机制的研究。'
                        }
                    ]
                },
                {
                    title: '专题三：储热和热泵在智慧区域综合能源的应用',
                    content: '当前世界面临着气候变暖、能源紧缺、环境恶化等困境，“碳中和”目标下的能源转型将是一次涵盖经济、社会、环境等的系统性变革。其中，区域综合能源系统通过对多能源类型和设备的综合集成优化，可实现对多种能源的优化调度控制和精准运行管理。而智慧化技术是助力区域综合能源系统实现高效有序的管理的必要手段。同时，热泵技术是电热综合能源系统的高效能源转换设备，也是实现热力系统零碳排放的关键技术路径。储热技术可与各类低碳技术结合，解决热量在时间和空间和供需错配，并可提升可在生能源消纳和电力系统灵活性。当前，该领域获得了学术界和工业届的广泛关注，但集成热泵、储热的智慧区域综合能源系统的研究仍然相对有限。本期特刊征集以下主题，但不限于:',
                    themeList: [
                        {
                            itemTitle: '基于热泵和储热的区域综合能源系统优化规划与运行',
                        },
                        {
                            itemTitle: '基于热泵和储热技术的零碳综合能源系统',
                        },
                        {
                            itemTitle: '先进的热泵和储热技术',
                        },
                        {
                            itemTitle: '大规模储热、跨季节储热、余热高效利用与区域综合能源系统',
                        },
                        {
                            itemTitle: '集成热泵、储热等的区域综合能源系统的技术经济性分析',
                        },
                        {
                            itemTitle: '智慧能源系统的感知、通信、仿真及控制技术与系统架构',
                        },
                        {
                            itemTitle: '人工智能算法在热泵、储热以及集成上述设备的区域综合能源系统的建模、分析与运行优化等的应用。',
                        }
                    ]
                },
                {
                    title: '专题四：先进能源材料',
                    content: '随着能源需求的不断增加和传统能源的逐渐短缺，绿色、环保、节能、高效的先进能源材料越来越受到人们的关注。先进能源材料是指具有优异的能量转换、储存和传输性能的材料。这些材料涉及燃料电池、锂电池、超级电容器和催化等多个领域。从可持续发展的角度来看，先进能源材料可以极大地满足全球不断增长的能源需求。另外，先进能源材料的利用可以大幅减少对化石燃料的依赖，减少温室气体的排放，从而缓解全球变暖和环境污染问题。对新型先进能源材料的研究是应对全球气候变化和资源短缺的关键途径。可以预见，先进能源材料在不久的将来将具有广阔的发展前景。 本特刊将重点关注先进能源材料的最新创新，特别是先进能源材料的合成、性能和应用研究。“先进能源材料”主题涵盖丰富的研究方向，包括但不限于基础科学研究，能量收集、转换和存储的最新进展，先进能源材料的表征和理论计算。'
                },
                {
                    title: '专题五：热光子学与能源应用',
                    content: '该专题旨在揭示热光子结构所蕴含的光学现象，明晰潜在的物理机制，并拓展其在能源领域的应用。上述研究将进一步补充和完善热光子学的基础理论和研究方法，促进光学、热物理等学科的多学科交叉深度融合，有望拓宽热科学前沿，为能源高效利用研究奠定基础。本期特刊征集以下主题的高质量原创研究论文：',
                    themeList: [
                        {
                            itemTitle: '热辐射调控（光谱、偏振、角度）',
                        },
                        {
                            itemTitle: '非平衡/线性热辐射',
                        },
                        {
                            itemTitle: '非互易热辐射',
                        },
                        {
                            itemTitle: '热光伏系统',
                        },
                        {
                            itemTitle: '热光子系统',
                        }
                    ]
                }
            ]
        }

    },
    mounted() {
        this.getDate();
    },
    methods:{
        processingDataSolicit(){
            this.list=this.Alldata.specialSubject
        }
    }
}
</script>

<style lang="less" scoped>
.center {
    display: flex;
    align-items: top;
    flex-shrink: 0;

    .content {
        padding: 30px;
        width: calc(100% - 60px);

        .title {
            margin-top: 20px;
        }

        .specialSubject {
            margin-bottom: 20px;
            .subject {
                padding: 10px;
                color: #fff;
                background-color: #2B77AE;
                font-weight: bold;
            }

            .subjectList {
                border: 1px solid #d1d1d1;
                box-sizing: border-box;
                padding: 10px;
                line-height: 25px;
                .subjecContent{
                    margin-bottom: 10px;
                }
                .item{
                    margin-bottom: 5px;
                    .itemTitle{
                        color: #2B77AE;
                        margin-bottom: 5px;
                    }
                    .itemContent{
                        color: #a0a0a0;
                    }
                }
            }
        }

    }
}
</style>